import React, { useState, useEffect } from "react";
import {
   Box,
   Typography,
   Button,
   // TextField,
   Link,
   // CircularProgress
} from "@mui/material";
import { colours, units } from "../constants/constants";
import { makeStyles } from "@mui/styles";
import IconCheckCircle from "../components/svg-icons/icon-check-circle";
// import { saveComments } from '../helpers/firebase';
// import { useSnackbar } from 'notistack';
// import { getUserId } from "../helpers/local-storage";
import {Helmet} from "react-helmet";
// import { getAuth } from "firebase/auth";

const ThankYou = () => {
   const classes = useStyles();
   // const [commentField, setCommentField] = useState("");
   const [urlCopied, setUrlCopied] = useState(false);
   // const [formSent, setFormSent] = useState(false);
   // const [loading, setLoading] = useState(false);
   // const { enqueueSnackbar } = useSnackbar();
   // const auth = getAuth();

   // const commentsHandler = (event) => {
   //    setCommentField(event.target.value);
   // };

   const copyUrl = () => {
      const el = document.createElement('input');
      el.value = "https://hurryapp.com.au";
      document.body.appendChild(el);
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);
      setUrlCopied(true);
   }

   // const submitCommentsHandler = () => {
   //    setLoading(true);
   //    try{
   //       const getUser = getUserId();
   //       const comment = commentField;
   //       const packComment = {
   //          userId: getUser,
   //          comment: comment
   //       };
   //       saveComments(packComment);
   //       setLoading(false);
   //       setFormSent(true);
   //       auth.signOut();
   //    } catch (error) {
   //       console.log(error);
   //       setLoading(false);
   //       enqueueSnackbar(`Oh no, something went wrong`, {
   //          variant: 'error',
   //          anchorOrigin: {
   //             vertical: 'top',
   //             horizontal: 'right',
   //          },
   //       });
   //    }
   // };

   // const noThanksHandler = () => {
   //    auth.signOut();
   //    window.location.href = "https://hurryapp.com.au"
   // }

   // const isContinueDisabled = !commentField;

   useEffect(() => {
      window.onbeforeunload = null;
   }, []);

   return (
      <>
         <Helmet>
            <meta charSet="utf-8" />
            <title>HurryApp - Your wishes</title>
            <meta name="description" content="What's your wish right now?" />
            <link rel="canonical" href="https://hurryapp.com" />
         </Helmet>
         <Box className={classes.mainWrapper}>
            <Box className={classes.sideGraph}>
               <Link href="https://hurryapp.com.au">
                  <img
                     width="80"
                     height="55"
                     src="/images/HurryApp-logo1-white.svg"
                     alt="HurryApp logo"
                     style={{ position: "relative", zIndex: 1 }}
                     className={classes.mainLogo}
                  />
                  <img
                     width="80"
                     height="55"
                     src="/images/HurryApp-isotipo-white.svg"
                     alt="HurryApp logo"
                     style={{ position: "relative", zIndex: 1 }}
                     className={classes.mobileMainLogo}
                  />
               </Link>
            </Box>
            <Box className={classes.sideThankyou}>
               <Box className={classes.shareContainer}>
                  <Box className={classes.preHeader} display="flex" justifyContent="center">
                     <IconCheckCircle stroke={colours.primary} />
                     <Typography
                        variant="h5"
                        style={{ color: colours.primary, marginLeft: units[1] }}
                     >
                        Thanks for submitting your Wishlist
                     </Typography>
                  </Box>
                  <Typography variant="h1" align="center">
                     Amazing!
                  </Typography>
                  <Box className={classes.sharingBlocks}>
                     <Box className={classes.sharingBlockFB}>
                        <img src="/images/ha-thank-you-illustration.svg" alt="Thanks for using HurryApp" className={classes.illustrationImage} />
                        <Box className={classes.sharingContent}>
                           <Typography align="center" variant="h4" component="h2" style={{marginBottom: units[2]}}>
                              One more thing…
                           </Typography>
                           <Typography align="center" variant="body1" gutterBottom style={{maxWidth: '600px', margin: '0 auto'}}>
                              You are all set. We'll come back to you soon with more news, but before going please spread the word and help us to help you beat higher prices forever :)
                           </Typography>
                           <Box mt={2} sx={{textAlign: 'center'}}>
                              <Button variant="contained" id="ha-button-share-url" color="primary" onClick={copyUrl}>
                                 {urlCopied ? "Copied!" : "Copy link"}
                              </Button>
                           </Box>
                        </Box>
                     </Box>
                     {/* <Box className={classes.sharingBlockSuggestions}>
                        {formSent ? (
                           <>
                              <Typography align="center" variant="h4" component="h2" style={{ marginBottom: units[3]}}>
                                 Thanks, we appreciate your feedback 🙌🏼
                              </Typography>
                              <Button variant="contained" color="primary" style={{marginRight: 16}} onClick={() => window.location.href = "https://hurryapp.com.au"}>Back to home</Button>
                           </>
                        ) : (
                           <>
                              <Typography variant="h4" align="center" component="h2" style={{ marginBottom: units[1]}}>
                                 Suggestions? Ideas? Improvements?
                              </Typography>
                              <Typography align="center" variant="body1" style={{ marginBottom: units[4]}}>
                                 HurryApp is for you, your feedback is really important
                                 to us.
                              </Typography>
                              <TextField
                                 variant="outlined"
                                 color="primary"
                                 label="Your comments"
                                 type="text"
                                 fullWidth
                                 value={commentField}
                                 onChange={(event) => commentsHandler(event)}
                                 multiline
                                 rows={4}
                              />
                              <Box mt={3} sx={{ textAlign: "right" }}>
                                 <Button variant="text" color="secondary" style={{marginRight: 16}} onClick={noThanksHandler}>No, thanks</Button>
                                 <Button
                                    variant="contained"
                                    color="primary"
                                    disabled={isContinueDisabled}
                                    onClick={submitCommentsHandler}
                                 >
                                    {loading ? <CircularProgress size={24} /> : "Submit feedback"}
                                 </Button>
                              </Box>
                           </>
                        )}
                     </Box> */}
                  </Box>
               </Box>
            </Box>
         </Box>
      </>
   );
};

const useStyles = makeStyles((theme) => ({
   mainWrapper: {
      width: "100%",
      minHeight: "100vh",
      display: "flex",
      flexDirection: "column",
      [theme.breakpoints.up('sm')]: {
         flexDirection: "row",
      },
   },
   sideGraph: {
      flex: '0 0 65px',
      background: `${colours.primary} url(/images/circled-pattern.png) repeat center center`,
      overflow: "hidden",
      borderBottomRightRadius: "100px",
      position: "relative",
      flexDirection: "column",
      justifyContent: "flex-start",
      alignItems: "center",
      boxSizing: "border-box",
      padding: "5px 16px",
      display: 'flex',
      [theme.breakpoints.up('sm')]: {
         padding: "16px",
      },
      [theme.breakpoints.up('md')]: {
         padding: "46px 40px 56px 40px",
         flex: "0 0 170px",
         minWidth: 170,
      },
   },
   mainLogo: {
      display: 'none',
      [theme.breakpoints.up("md")]: {
         display: 'block',
         width: 80,
      },
   },
   mobileMainLogo: {
      width: 35,
      [theme.breakpoints.up("md")]: {
         display: 'none',
      },
   },
   preHeader: {
      marginBottom: units[1],
      [theme.breakpoints.up('md')]: {
         marginBottom: units[2],
      },
   },
   illustrationImage: {
      maxWidth: '150px',
      width: '100%',
      height: 'auto',
      margin: '0 auto',
      paddingBottom: units[2],
      flex: 1,
      position: "relative",
      [theme.breakpoints.up('md')]: {
         maxWidth: '220px',
      },
   },
   sharingContent: {
      flex: 1
   },
   sideThankyou: {
      flex: 1,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      padding: '16px',
      marginBottom: '32px',
      [theme.breakpoints.up('md')]: {
         padding: '24px',
      },
      [theme.breakpoints.up('lg')]: {
         padding: '32px',
         marginRight: "100px",
      }
   },
   shareContainer: {
      flex: 1,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
   },
   sharingBlocks: {
      maxWidth: 800,
      width: "100%",
      display: "flex",
      flexDirection: "column",
      marginTop: units[1],
   },
   sharingBlockFB: {
      flex: 1,
      display: "flex",
      flexDirection: "column",
      padding: units[2],
   },
   sharingBlockSuggestions: {
      border: `solid ${colours.primaryDark} 2px`,
      borderRadius: units[2],
      padding: units[2],
      flex: 1,
      display: "flex",
      flexDirection: "column",
      [theme.breakpoints.up('md')]: {
         padding: units[3],
      },
      [theme.breakpoints.up('lg')]: {
         padding: units[4],
      },
   },
}));

export default ThankYou;
