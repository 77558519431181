import { units, colours } from "./constants";
import { makeStyles } from "@mui/styles";

const globalStyling = makeStyles((theme) => ({
   formGroup: {
      display: "flex",
      columnGap: "16px",
      flexDirection: 'column',
      [theme.breakpoints.up("md")]: {
         columnGap: "16px",
         flexDirection: 'row'
      },
   },
   inputs: {
      marginBottom: units[3] + "!important",
   },
   closeButton: {
      backgroundColor: colours.white + "!important",
      color: colours.secondary + "!important",
      "&:hover": {
         color: colours.primary + "!important",
      },
   },
}));

export default globalStyling;
