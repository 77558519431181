import { colours } from '../constants';

export const MuiPalette = {
  palette: {
    primary: {
      main: colours.primary,
    },
    secondary: {
      main: colours.secondary,
    },
  },
};

export default MuiPalette;
