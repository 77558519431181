import React from "react";
import { colours } from "../../constants/constants";

const IconCheckCircle = (props) => {
   const { width = 24, height = 24, stroke = colours.black } = props;
   return (
      <svg
         style={{ width: width, height: height }}
         viewBox={`0 0 ${width} ${height}`}
         xmlns="http://www.w3.org/2000/svg"
      >
         <g transform="matrix(1,0,0,1,0,0)">
            <path
               d="M6,13.223,8.45,16.7a1.049,1.049,0,0,0,1.707.051L18,6.828"
               fill="none"
               stroke={stroke}
               strokeLinecap="round"
               strokeLinejoin="round"
               strokeWidth="1.5"
            ></path>
            <path
               d="M0.750 11.999 A11.250 11.250 0 1 0 23.250 11.999 A11.250 11.250 0 1 0 0.750 11.999 Z"
               fill="none"
               stroke={stroke}
               strokeLinecap="round"
               strokeLinejoin="round"
               strokeWidth="1.5"
            ></path>
         </g>
      </svg>
   );
};

export default IconCheckCircle;
