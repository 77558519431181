import React from "react";
import {
   Dialog,
   DialogTitle,
   DialogContent,
   Typography,
   Fab,
   Link,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import globalStyling from "../constants/global-styling";
import CloseIcon from "@mui/icons-material/Close";

const TermsDialog = (props) => {
   const classes = useStyles();
   const globalClasses = globalStyling();
   const { isOpen, handleClose } = props;

   return (
      <Dialog open={isOpen} onClose={handleClose} maxWidth="sm" fullWidth>
         <DialogTitle>
            Privacy Policy
            <Fab
               aria-label="close"
               color="primary"
               size="small"
               className={globalClasses.closeButton}
               onClick={handleClose}
            >
               <CloseIcon />
            </Fab>
         </DialogTitle>
         <DialogContent className={classes.mainWrapper}>
            <Typography variant="body2" gutterBottom>
               This policy explains what you can expect from us and what we need
               from you in relation to your personal data. Please read this
               carefully as this policy is legally binding from the moment you
               give your consent to it.
            </Typography>
            <Typography variant="body2" gutterBottom>
               For the purpose of the relevant data protection regulations
               HurryApp and NXQ Group Pty Ltd ("HurryApp", "we", "us", or "our"
               as applicable) are the “data controller” of your information. We
               are located on 49A Denman Parade, Normanhurst NSW 2076. Our
               registration number with Australian Securities &amp; Investments
               Commission is 652 317 943. If you have any questions about how we
               protect or use your data, please email us at
               hurryapp@nxqgroup.com.au.
            </Typography>
            <Typography variant="subtitle2" gutterBottom>
               1. How do we protect your personal data
            </Typography>
            <ul>
               <Typography variant="body2" gutterBottom component="li">
                  1.1 We are serious about guarding the security of your
                  personal information and use a secure server to store your
                  personal information. All information you provide to us is
                  stored with high security standards.
               </Typography>
               <Typography variant="body2" gutterBottom component="li">
                  1.2 As you will know, the transmission of information via the
                  internet is not completely secure. Although we will do our
                  best to protect your personal data, we cannot guarantee the
                  security of your data during transmission, and any
                  transmission is at your own risk. Once we have received your
                  information, we will use strict procedures and security
                  features to try to prevent unauthorised access.
               </Typography>
               <Typography variant="body2" gutterBottom component="li">
                  1.2 As you will know, the transmission of information via the
                  internet is not completely secure. Although we will do our
                  best to protect your personal data, we cannot guarantee the
                  security of your data during transmission, and any
                  transmission is at your own risk. Once we have received your
                  information, we will use strict procedures and security
                  features to try to prevent unauthorised access.
               </Typography>
            </ul>
            <Typography variant="subtitle2" gutterBottom>
               2. Information we may collect from you
            </Typography>
            <Typography variant="body2" gutterBottom>
               We may collect and use the following data about you:
            </Typography>
            <ul>
               <Typography variant="body2" gutterBottom component="li">
                  2.1 Information you give us.
               </Typography>
               <ul>
                  <Typography variant="body2" gutterBottom component="li">
                     You may give us information about you when you sign up to
                     use our service or be part of any of our users wishlists,
                     e.g. when you provide us with your personal details, such
                     as your name and email address. This also includes
                     information you provide through your continued use of our
                     services, participate in discussion boards or other social
                     media functions on our website or app, enter a competition,
                     promotion or survey, and when you report a problem with our
                     services. The information you give us may include your
                     name, age, e-mail address, age, personal description and
                     photograph.
                  </Typography>
               </ul>
               <Typography variant="body2" gutterBottom component="li">
                  2.2 Information we collect about you with regard to your use
                  of our services or participation in wishlist exercises. We may
                  automatically collect the following information:
               </Typography>
               <ul>
                  <Typography variant="body2" gutterBottom component="li">
                     (a) technical information, including the Internet protocol
                     (IP) address used to connect your computer to the Internet,
                     your login information, browser type and version, time zone
                     setting, browser plug-in types and versions, operating
                     system and platform;
                  </Typography>
                  <Typography variant="body2" gutterBottom component="li">
                     (b) information about your visit, including the full
                     Uniform Resource Locators (URL) clickstream to, through and
                     from our Website or App (including date and time); products
                     you viewed or searched for; page response times, download
                     errors, length of visits to certain pages, page interaction
                     information (such as scrolling, clicks, and mouse-overs),
                     and methods used to browse away from the page and any phone
                     number used to call our Customer Support number.
                  </Typography>
               </ul>
            </ul>
            <Typography variant="subtitle2" gutterBottom>
               3. Cookies
            </Typography>
            <ul>
               <Typography variant="body2" component="li" gutterBottom>
                  3.1 Our Services use cookies to distinguish you from other
                  users. This helps us to provide you with a good experience and
                  also allows us to improve our Services.
               </Typography>
            </ul>
            <Typography variant="subtitle2" gutterBottom>
               4. Uses made of the information
            </Typography>
            <ul>
               <Typography variant="body2" gutterBottom component="li">
                  4.1 We use your information in the following ways:
               </Typography>
               <ul>
                  <Typography variant="body2" gutterBottom component="li">
                     (a) to carry out our obligations relating to your contracts
                     with us and to provide you with the information, products
                     and services that you request from us;
                  </Typography>
                  <Typography variant="body2" gutterBottom component="li">
                     (b) to comply with any applicable legal and/or regulatory
                     requirements;
                  </Typography>
                  <Typography variant="body2" gutterBottom component="li">
                     (b) to comply with any applicable legal and/or regulatory
                     requirements;
                  </Typography>
                  <Typography variant="body2" gutterBottom component="li">
                     (c) to gather data about demand regarding specific goods
                     and services to be potentially offered through HurryApp’s
                     platform;
                  </Typography>
                  <Typography variant="body2" gutterBottom component="li">
                     (d) as part of our efforts to keep our services safe and
                     secure;
                  </Typography>
                  <Typography variant="body2" gutterBottom component="li">
                     (e) to administer our Website and for internal operations,
                     including troubleshooting, data analysis, testing,
                     research, statistical and survey purposes;
                  </Typography>
                  <Typography variant="body2" gutterBottom component="li">
                     (f) to improve our services and to ensure that they are
                     presented in the most effective manner;
                  </Typography>
                  <Typography variant="body2" gutterBottom component="li">
                     (g) to measure or understand the effectiveness of
                     advertising we serve and to deliver relevant advertising to
                     you;
                  </Typography>
                  <Typography variant="body2" gutterBottom component="li">
                     (h) to allow you to participate in interactive features of
                     our services, when you choose to do so;
                  </Typography>
                  <Typography variant="body2" gutterBottom component="li">
                     (i) to provide you with information about other similar
                     goods and services we offer;
                  </Typography>
                  <Typography variant="body2" gutterBottom component="li">
                     (j) to provide you, or permit selected third parties to
                     provide you, with information about goods or services we
                     feel may interest you;
                  </Typography>
                  <Typography variant="body2" gutterBottom component="li">
                     (k) combine information we receive from other sources with
                     the information you give to us and information we collect
                     about you. We may use this information and the combined
                     information for the purposes set out above (depending on
                     the types of information we receive); or
                  </Typography>
                  <Typography variant="body2" gutterBottom component="li">
                     (l) provide information, in an aggregated manner, about
                     potential HarryApp users’ interests to potential investors
                     in HurryApp or potential merchants.
                  </Typography>
               </ul>
            </ul>
            <Typography variant="subtitle2" gutterBottom>
               5. Disclosure of your information
            </Typography>
            <ul>
               <Typography variant="body2" component="li" gutterBottom>
                  5.1 We may share your information with selected third parties
                  including:
               </Typography>
               <ul>
                  <Typography variant="body2" component="li" gutterBottom>
                     (a) affiliates, business partners, suppliers and
                     sub-contractors for the performance and execution of any
                     contract we enter into with them or you;
                  </Typography>
                  <Typography variant="body2" component="li" gutterBottom>
                     (b) advertisers and advertising networks solely to select
                     and serve relevant adverts to you and others; and
                  </Typography>
                  <Typography variant="body2" component="li" gutterBottom>
                     (c) analytics and search engine providers that assist us in
                     the improvement and optimisation of our site; and
                  </Typography>
                  <Typography variant="body2" component="li" gutterBottom>
                     (d) merchants that are part or are interested in
                     participating in HurryApp’s platform
                  </Typography>
                  <Typography variant="body2" component="li" gutterBottom>
                     (e) investors or potential investors in HurryApp
                  </Typography>
               </ul>
               <Typography variant="body2" component="li" gutterBottom>
                  5.2 We may disclose your personal data to third parties:
               </Typography>
               <ul>
                  <Typography variant="body2" component="li" gutterBottom>
                     (a) in the event that we sell or buy any business or
                     assets, in which case we may disclose your personal data to
                     the prospective seller or buyer of such business or assets;
                  </Typography>
                  <Typography variant="body2" component="li" gutterBottom>
                     (b) if we are under a duty to disclose or share your
                     personal data in order to comply with any legal
                     obligation,=; or to protect the rights, property, or safety
                     of HurryApp, our customers, or others. This includes
                     exchanging information with other companies and
                     organisations for the purposes of fraud protection and
                     credit risk reduction;
                  </Typography>
                  <Typography variant="body2" component="li" gutterBottom>
                     (c) to assist us in conducting or co-operating in
                     investigations of fraud or other illegal activity where we
                     believe it is reasonable and appropriate to do so;
                  </Typography>
                  <Typography variant="body2" component="li" gutterBottom>
                     (d) to prevent and detect fraud or crime;
                  </Typography>
                  <Typography variant="body2" component="li" gutterBottom>
                     (e) in response to a subpoena, warrant, court order, or as
                     otherwise required by law;
                  </Typography>
                  <Typography variant="body2" component="li" gutterBottom>
                     (f) to assess financial and insurance risks;
                  </Typography>
                  <Typography variant="body2" component="li" gutterBottom>
                     (g) to recover debt or in relation to your insolvency; and
                  </Typography>
                  <Typography variant="body2" component="li" gutterBottom>
                     (h) to develop customer relationships, services and
                     systems.
                  </Typography>
               </ul>
               <Typography variant="body2" component="li" gutterBottom>
                  5.3 We do not have a published list of all of the third
                  parties with whom we share your data with, as this would be
                  heavily dependant on your specific use of our Services.
                  However, if you would like further information about who we
                  have shared your data with, or to be provided with a list
                  specific to You, you can request this by writing to
                  hurryapp@nxqgroup.com.au.
               </Typography>
            </ul>
            <Typography variant="subtitle2" gutterBottom>
               6. Where we store your personal data
            </Typography>
            <ul>
               <Typography variant="body2" gutterBottom component="li">
                  6.1 The data that we collect from you may be transferred to,
                  and stored at, a destination outside Australia. It may also be
                  processed by staff operating outside Australia who work for us
                  or for one of our suppliers. By submitting your personal data,
                  you agree to this transfer, storing or processing. We will
                  take all steps reasonably necessary to ensure that your data
                  is treated securely and in accordance with this privacy
                  policy.
               </Typography>
            </ul>
            <Typography variant="subtitle2" gutterBottom>
               7. Sharing your data with third parties
            </Typography>
            <ul>
               <Typography variant="body2" gutterBottom component="li">
                  7.1 In order to process your data as stated in this privacy
                  policy, it is sometimes necessary for us to transfer your data
                  to the third parties outlined in section 5.1 that are based
                  outside of Australia. In these cases, we ensure that both
                  ourselves and our partners take adequate and appropriate
                  technical, physical and organisational security measures to
                  protect your data. We also ensure we have appropriate
                  contractual protections in place with these third parties.
               </Typography>
            </ul>
            <Typography variant="subtitle2" gutterBottom>
               8. Profiling and Automated Decision Making
            </Typography>
            <Typography variant="body2" gutterBottom>
               We may use some instances of your data in order customise our
               services and the information we provide to you, to design and
               implement our business plan and strategy, and to address your
               needs - such as your country of address and transaction history.
               When we do this, we take all necessary measures to ensure that
               your privacy and security are protected - and we only use
               pseudonymised data where ever possible. This activity has no
               legal effect on you.
            </Typography>
            <Typography variant="subtitle2" gutterBottom>
               9. Data Retention
            </Typography>
            <ul>
               <Typography variant="body2" gutterBottom component="li">
                  9.1 Your data is only accessed internally on a need to know
                  basis, and it will only be accessed or processed if absolutely
                  necessary.
               </Typography>
               <Typography variant="body2" gutterBottom component="li">
                  9.2 We will always delete data that is not longer required for
                  the purposes stated herein or by a relevant law or
                  jurisdiction in which we operate.
               </Typography>
            </ul>
            <Typography variant="subtitle2" gutterBottom>
               10. Your rights
            </Typography>
            <ul>
               <Typography variant="body2" gutterBottom component="li">
                  10.1 You have the right to ask us not to contact you for
                  marketing purposes by contacting us at
                  hurryapp@nxqgroup.com.au.
               </Typography>
               <Typography variant="body2" gutterBottom component="li">
                  10.2 You have the right to correct any personal information We
                  hold on you that is inaccurate, incorrect, or out of date.
               </Typography>
               <Typography variant="body2" gutterBottom component="li">
                  10.3 You have the right to ask us to delete your data when it
                  is no longer necessary, or no longer subject to a legal
                  obligation to which HurryApp is subject to.
               </Typography>
               <Typography variant="body2" gutterBottom component="li">
                  10.4 You have the right to transfer your personal data between
                  data controllers, for example, to move your account details
                  from one online platform to another.
               </Typography>
               <Typography variant="body2" gutterBottom component="li">
                  10.5 Our Services may, from time to time, contain links to and
                  from the websites of our partner networks, advertisers and
                  affiliates. If you follow a link to any of these websites,
                  please note that these websites have their own privacy
                  policies and that we do not accept any responsibility for
                  them. Please check these policies before you submit any
                  personal data to these websites.
               </Typography>
            </ul>
            <Typography variant="subtitle2" gutterBottom>
               11. Access to information
            </Typography>
            <ul>
               <Typography variant="body2" gutterBottom component="li">
                  11.1 Subject to applicable laws, you may have the right to
                  access information we held about you. Your right of access can
                  be exercised in accordance with the relevant data protection
                  legislation.
               </Typography>
            </ul>
            <Typography variant="subtitle2" gutterBottom>
               12. Changes to our privacy policy
            </Typography>
            <ul>
               <Typography variant="body2" gutterBottom component="li">
                  12.1 Any changes we may make to our privacy policy will be
                  posted on this page and, where appropriate, notified to you by
                  e-mail. Please check back frequently to see any updates or
                  changes to our privacy policy.
               </Typography>
            </ul>
            <Typography variant="subtitle2" gutterBottom>
               13. Contact
            </Typography>
            <ul>
               <Typography variant="body2" gutterBottom component="li">
                  13.1 Questions, comments and requests regarding this privacy
                  policy are welcomed and should be addressed to
                  hurryapp@nxqgroup.com.au.
               </Typography>
               <Typography variant="body2" gutterBottom component="li">
                  13.2 If you feel that we have not addressed your questions or
                  concerns adequately, you may make a complaint with the{" "}
                  <Link
                     href="https://www.oaic.gov.au/about-us/contact-us"
                     target="_blank"
                  >
                     Australian Information Commissioner in Australia.
                  </Link>
               </Typography>
            </ul>
         </DialogContent>
      </Dialog>
   );
};

const useStyles = makeStyles((theme) => ({
   mainWrapper: {
      margin: "16px 0",
      "& ul": {
         paddingInlineStart: "16px",
      }
   },
}));

export default TermsDialog;
