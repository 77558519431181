
import React from 'react';
import {
   Dialog,
   DialogTitle,
   DialogContent,
   DialogActions,
   Button,
   Box,
   Fab,
   Typography,
} from "@mui/material";
import globalStyling from '../constants/global-styling';
import CloseIcon from '@mui/icons-material/Close';
import { makeStyles } from '@mui/styles';
import create from 'zustand';

const useConfirmDialogStore = create((set) => ({
   message: '',
   onSubmit: undefined,
   close: () => set({ onSubmit: undefined }),
}));

const DeleteCofirmationDialog = () => {
   const classes = useStyles();
   const globalClasses = globalStyling();
   const { message, onSubmit, close } = useConfirmDialogStore();

   return (
      <Dialog open={Boolean(onSubmit)} onClose={close} maxWidth="sm" fullWidth>
         <DialogTitle>
            Confirmation
            <Fab
               aria-label="close"
               color="primary"
               size="small"
               className={globalClasses.closeButton}
               onClick={close}
            >
               <CloseIcon />
            </Fab>
         </DialogTitle>
         <DialogContent className={classes.dialogContent}>
            <Box mb={2}>
               <Typography variant="subtitle2">{message}</Typography>
            </Box>
         </DialogContent>
         <DialogActions sx={{padding: "24px"}}>
            <Button variant="text" color="primary" size="small" onClick={close}>No</Button>
            <Button variant="contained" color="primary" size="small" onClick={() => {
            if (onSubmit) {
              onSubmit();
            }
            close();
          }}>Yes</Button>
         </DialogActions>
      </Dialog>
   )
};

export const confirmDialog = (message, onSubmit) => {
   useConfirmDialogStore.setState({
     message,
     onSubmit,
   });
 };

const useStyles = makeStyles(theme => ({
   dialogContent: {
      paddingTop: '16px !important',
      paddingBottom: '16px !important',
   }
}));

export default DeleteCofirmationDialog;