import { colours } from "../constants";

export const MuiButton = {
   MuiIconButton: {
      styleOverrides: {
         root: {
            "&.Mui-disabled svg": {
               opacity: "0.25",
            },
         },
      },
   },
   MuiButton: {
      styleOverrides: {
         root: {
            borderRadius: 16,
            // color: colours.white,
            overflow: "hidden",
            fontSize: "16px",
            lineHeight: "23px",
            paddingTop: 12,
            paddingBottom: 13,
            paddingLeft: 21,
            paddingRight: 21,
         },
         sizeSmall: {
            fontSize: "14px",
            lineHeight: "22px",
            paddingTop: 9,
            paddingBottom: 9,
            paddingLeft: 16,
            paddingRight: 16,
            "& .Streamline_Icon svg": {
               width: "22px !important",
               height: "22px !important",
            },
         },
         sizeLarge: {
            fontSize: "16px",
            lineHeight: "24px",
            paddingTop: 16,
            paddingBottom: 16,
            paddingLeft: 24,
            paddingRight: 24,
            "& .Streamline_Icon svg": {
               width: "24px !important",
               height: "24px !important",
            },
         },
         contained: {
            backgroundColor: colours.white,
            color: colours.primary,
            boxShadow: "none",
         },
         containedPrimary: {
            backgroundColor: colours.primary,
            color: colours.white,
            "&:hover, &:focus, &:active, &.activated": {
               backgroundColor: colours.primaryDark,
            },
         },
         containedSecondary: {
            backgroundColor: colours.white,
            color: colours.black,
            "&:hover, &:focus, &:active, &.activated": {
               backgroundColor: colours.gray[200],
            },
         },
         outlined: {
            border: 0,
            boxShadow: "inset 0 0 0 2px " + colours.white,
            color: colours.white,
            paddingTop: 14,
            paddingBottom: 14,
            paddingLeft: 21,
            paddingRight: 21,
            "&:hover, &:active": {
               border: 0,
               boxShadow: "inset 0 0 0 2px " + colours.primaryLight,
               color: colours.primaryLight,
            },
         },
         outlinedPrimary: {
            border: 0,
            boxShadow: `inset 0 0 0 2px ${colours.primary}`,
            color: colours.primary,
            "&.Mui-disabled": {
               border: 0,
               boxShadow: "inset 0 0 0 2px " + colours.gray[300],
            },
            "&:hover, &:focus, &:active, &.activated": {
               border: 0,
               color: colours.primaryDark,
               boxShadow:
                  "inset 0 0 0 2px " + colours.primaryDark,
            },
         },
         outlinedSecondary: {
            border: 0,
            boxShadow: `inset 0 0 0 2px ${colours.secondary}`,
            color: colours.secondary,
            "&.Mui-disabled": {
               border: 0,
               boxShadow: "inset 0 0 0 2px " + colours.gray[300],
            },
            "&:hover, &:focus &:active, &.activated": {
               border: 0,
               color: colours.primary,
               boxShadow: "inset 0 0 0 2px " + colours.primary,
            },
         },
      },
   },
};

export default MuiButton;
