import React from 'react';
import { units, colours } from '../constants/constants';
import { Box, Typography } from '@mui/material';
import IconPlusCircle from "./svg-icons/icon-plus-circle";

const ButtonTile = (props) => {
   const {style, className, icon, title, subtitle, onClick, showSide} = props;
   return (
      <Box sx={{...styles.tileWrapper, ...style}} onClick={onClick} className={className}>
         {showSide ? <Box sx={styles.triggerArea}>
            <IconPlusCircle stroke="#FFFFFF" />
         </Box> : null}
         <Box sx={styles.textArea}>
            {icon ? <div style={{marginBottom: units[1]}}>{icon}</div> : null}
            <Typography variant="h6" component="div" gutterBottom>{title}</Typography>
            <Typography variant="body2" component="div">{subtitle}</Typography>
         </Box>
      </Box>
   );
};

const styles = {
   tileWrapper: {
      border: `solid 1px ${colours.gray[300]}`,
      borderRadius: units[2],
      overflow: 'hidden',
      display: 'flex',
      flexDirection: 'row',
      cursor: 'pointer',
      boxSizing: 'border-box',
      transition: 'box-shadow 0.2s ease-out',
      '&:hover': {
         borderColor: colours.primaryDark,
         boxShadow: `inset 0 0 0 1px ${colours.primaryDark}`,
         '& > div:first-of-type': {
            backgroundColor: colours.primaryDark
         }
      }
   },
   triggerArea: {
      backgroundColor: colours.primary,
      padding: units[2],
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      transition: 'background-color 0.2s ease-out',
   },
   textArea: {
      padding: units[2],
   }
};

export default ButtonTile;