import React, { useState, Fragment, useEffect } from "react";
import {
   Dialog,
   DialogTitle,
   DialogContent,
   DialogActions,
   List,
   ListItem,
   ListItemIcon,
   Collapse,
   ListItemText,
   Checkbox,
   IconButton,
   Button,
   Fab,
   Typography,
} from "@mui/material";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { colours } from "../constants/constants";
import globalStyling from "../constants/global-styling";
import CloseIcon from "@mui/icons-material/Close";
import { makeStyles } from "@mui/styles";

const CategorySelectionDialog = (props) => {
   const globalClasses = globalStyling();
   const classes = useStyles();

   // Local State
   const {
      isOpen,
      handleClose,
      categories,
      saveCategory,
      savedCategories,
      listener,
   } = props;
   const [accOpen, setAccOpen] = useState([]);
   const [selectedCats, setSelectedCats] = useState([]);

   //Constants
   const parentCats = categories.filter((cats) => !cats.parentId);

   // Handlers
   const saveLocalCategoryHandler = (cat) => {
      const currentIndex = selectedCats.findIndex((cats) => cats.id === cat.id);
      const newCategory = [...selectedCats];
      if (currentIndex === -1) {
         newCategory.push(cat);
      } else {
         newCategory.splice(currentIndex, 1);
      }
      setSelectedCats(newCategory);
   };

   const saveCategoriesHandler = () => {
      saveCategory(selectedCats);
      setSelectedCats([]);
      handleClose();
   };

   const cancelEditionHandler = () => {
      setSelectedCats([]);
      handleClose();
   };

   const accordionHandle = (catId) => {
      const currentIndex = accOpen.indexOf(catId);
      const newAccOpen = [...accOpen];

      if (currentIndex === -1) {
         newAccOpen.push(catId);
      } else {
         newAccOpen.splice(currentIndex, 1);
      }
      setAccOpen(newAccOpen);
   };

   const SubList = (props) => {
      const { children, catId } = props;
      return (
         <Collapse
            in={accOpen.indexOf(catId) !== -1}
            timeout="auto"
            unmountOnExit
         >
            <List dense component="div" disablePadding>
               {children}
            </List>
         </Collapse>
      );
   };

   const ListFormatted = (props) => {
      const { hasChildren, catId, catName, indentation, selectable, parentId } = props;
      return (
         <ListItem
            sx={{
               pl: indentation ? indentation : "16px",
               cursor: selectable ? "pointer" : "unset",
               backgroundColor:
                  selectedCats.findIndex((cat) => cat.id === catId) !== -1
                     ? "#fff6ed"
                     : "#FFFFFF",
            }}
         >
            {hasChildren ? (
               <ListItemIcon sx={{ cursor: "pointer", minWidth: "40px" }}>
                  <IconButton
                     size="small"
                     color="primary"
                     aria-label="expand"
                     onClick={() => accordionHandle(catId)}
                  >
                     {accOpen.indexOf(catId) !== -1 ? (
                        <KeyboardArrowDownIcon />
                     ) : (
                        <KeyboardArrowRightIcon />
                     )}
                  </IconButton>
               </ListItemIcon>
            ) : (
               <ListItemIcon sx={{ minWidth: "40px !important" }}></ListItemIcon>
            )}
            {selectable ? (
               <ListItemIcon sx={{ minWidth: "32px !important" }}>
                  <Checkbox
                     edge="start"
                     onClick={() =>
                        saveLocalCategoryHandler({
                           id: catId,
                           parentId: parentId,
                           categoryName: catName,
                        })
                     }
                     checked={
                        selectedCats.findIndex((cat) => cat.id === catId) !== -1
                     }
                     tabIndex={-1}
                     disableRipple
                     inputProps={{ "aria-labelledby": catId }}
                  />
               </ListItemIcon>
            ) : null}
            <ListItemText
               primary={catName}
               sx={{cursor:"pointer"}}
               onClick={() =>
                  selectable
                     ? saveLocalCategoryHandler({
                          id: catId,
                          categoryName: catName,
                       })
                     : accordionHandle(catId)
               }
            />
         </ListItem>
      );
   };

   const hasChildren = (catId) => {
      let cats = categories.find((value) => value.parentId === catId);
      return cats ? true : false;
   };

   const getFilteredCats = (filterId) => {
      return categories.filter((cats) => cats.parentId === filterId);
   };

   useEffect(() => {
      if (savedCategories) {
         setSelectedCats(savedCategories);
      } else {
         setSelectedCats([]);
      }
   // eslint-disable-next-line
   }, [isOpen, listener]);

   return (
      <Dialog
         open={isOpen}
         onClose={cancelEditionHandler}
         maxWidth="sm"
         fullWidth
         className={classes.root}
      >
         <DialogTitle>
            Add a category
            <Fab
               aria-label="close"
               color="primary"
               size="small"
               className={globalClasses.closeButton}
               onClick={cancelEditionHandler}
            >
               <CloseIcon />
            </Fab>
         </DialogTitle>
         <DialogContent className={classes.dialogContent}>
            <Typography variant="subtitle2">
               Expand the categories to discover and add a one or mutiple
               categories to your list
            </Typography>
         </DialogContent>
         <DialogContent className={classes.dialogContentTwo}>
            <List
               sx={{
                  width: "100%",
                  border: `solid 1px ${colours.gray[200]}`,
               }}
               component="nav"
               aria-labelledby="nested-list-subheader"
               className={classes.categoryList}
            >
               {parentCats.map((level1) => (
                  <Fragment key={"ha-cat-key" + level1.id}>
                     {/* Level 1 */}
                     <ListFormatted
                        selectable={false}
                        catId={level1.id}
                        catName={level1.categoryName}
                        parentId={level1.parentId}
                        hasChildren={hasChildren(level1.id)}
                     />
                     {/* Level 2 */}
                     {hasChildren(level1.id) && (
                        <SubList catId={level1.id}>
                           {getFilteredCats(level1.id).map((level2) => (
                              <Fragment key={"ha-cat-key" + level2.id}>
                                 <ListFormatted
                                    selectable={true}
                                    catId={level2.id}
                                    parentId={level2.parentId}
                                    catName={level2.categoryName}
                                    hasChildren={hasChildren(level2.id)}
                                    indentation={3.5}
                                 />
                                 {/* Level 3 */}
                                 {hasChildren(level2.id) && (
                                    <SubList catId={level2.id}>
                                       {getFilteredCats(level2.id).map(
                                          (level3) => (
                                             <Fragment
                                                key={"ha-cat-key" + level3.id}
                                             >
                                                <ListFormatted
                                                   selectable={true}
                                                   catId={level3.id}
                                                   parentId={level3.parentId}
                                                   catName={level3.categoryName}
                                                   hasChildren={hasChildren(
                                                      level3.id
                                                   )}
                                                   indentation={5}
                                                />
                                                {/* Level 4 */}
                                                {hasChildren(level3.id) && (
                                                   <SubList catId={level3.id}>
                                                      {getFilteredCats(
                                                         level3.id
                                                      ).map((level4) => (
                                                         <Fragment
                                                            key={
                                                               "ha-cat-key" +
                                                               level4.id
                                                            }
                                                         >
                                                            <ListFormatted
                                                               selectable={true}
                                                               catId={level4.id}
                                                               parentId={level4.parentId}
                                                               catName={
                                                                  level4.categoryName
                                                               }
                                                               hasChildren={hasChildren(
                                                                  level4.id
                                                               )}
                                                               indentation={6.5}
                                                            />
                                                         </Fragment>
                                                      ))}
                                                   </SubList>
                                                )}
                                             </Fragment>
                                          )
                                       )}
                                    </SubList>
                                 )}
                              </Fragment>
                           ))}
                        </SubList>
                     )}
                  </Fragment>
               ))}
            </List>
         </DialogContent>
         <DialogActions sx={{ padding: "24px" }}>
            <Button
               id="ha-wishlisth-category-added"
               variant="contained"
               color="primary"
               size="small"
               disabled={false}
               onClick={saveCategoriesHandler}
            >
               Add to my wishlist
            </Button>
         </DialogActions>
      </Dialog>
   );
};

const useStyles = makeStyles((theme) => ({
   root: {
      "& .MuiPaper-root": {
         height: "100%",
         maxHeight: "660px",
      },
   },
   categoryList: {
      "& li": {
         borderBottom: "solid 1px #e9e5e5",
      },
      "& li:last-child": {
         borderBottom: "none",
      },
   },
   dialogContent: {
      paddingTop: "16px !important",
      paddingBottom: "16px !important",
   },
   dialogContentTwo: {
      paddingTop: "0 !important",
      paddingBottom: "0 !important",
      flex: "0 0 426px !important",
      position: "relative",
      overflowX: 'hidden',
      "&:after": {
         content: "' '",
         position: "sticky",
         display: "block",
         left:0,
         bottom: 0,
         marginBottom: '-100%',
         zIndex: 1,
         width: "100%",
         height: "80px",
         background: "linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%)",
         pointerEvents: "none"
      },
   },
}));

export default CategorySelectionDialog;
