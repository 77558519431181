import { createTheme } from "@mui/material/styles";
import { colours, units } from "./constants";
import MuiButton from "./MUI-overrides/MUI-buttons";
import MuiPalette from "./MUI-overrides/MUI-palette";
import MuiBackdrop from "./MUI-overrides/MUI-backdrop";

let theme = createTheme();
theme = createTheme(theme, {
   MuiScopedCssBaseline: {
      root: {
         backgroundColor: colours.gray[200],
         fontFamily: "lexend, sans-serif",
      },
   },
   ...MuiPalette,
   components: {
      ...MuiButton,
      ...MuiBackdrop,
      MuiPaper: {
         styleOverrides: {
            root: {
               borderRadius: units[2],
               boxShadow: "rgb(62 80 96 / 20%) 0px 4px 20px",
            },
         },
      },
      MuiDialogTitle: {
         styleOverrides: {
            root: {
               backgroundColor: colours.primary,
               display: "flex",
               justifyContent: "space-between",
               alignItems: "center",
               fontSize: "22px",
               [theme.breakpoints.up("md")]: {
                  fontSize: "24px",
               },
               color: colours.white,
            },
         },
      },
      MuiFab: {
         styleOverrides: {
            root: {
               boxShadow: "none",
            },
         },
      },
   },
   typography: {
      fontFamily: "lexend, sans-serif",
      fontWeightLight: 300,
      fontWeightRegular: 400,
      fontWeightMedium: 500,
      fontWeightBold: 700,
      h1: {
         fontWeight: 700,
         letterSpacing: "-1.38px",
         fontSize: "42px",
         color: colours.black,
         fontFamily: "lexend, sans-serif",
         lineHeight: "100%",
         [theme.breakpoints.up("md")]: {
            fontSize: "52px",
         },
      },
      h2: {
         fontWeight: 700,
         fontFamily: "lexend, sans-serif",
         letterSpacing: "-0.5px",
         fontSize: "2.125rem",
         color: colours.black,
         lineHeight: "calc(100% + 4px)",
      },
      h3: {
         fontWeight: 600,
         fontFamily: "lexend, sans-serif",
         letterSpacing: "-0.5px",
         fontSize: "1.875rem",
         color: colours.black,
         lineHeight: "calc(100% + 4px)",
      },
      h4: {
         fontWeight: 500,
         fontFamily: "lexend, sans-serif",
         letterSpacing: "-0.5px",
         fontSize: "1.625rem",
         color: colours.black,
         lineHeight: "calc(100% + 4px)",
      },
      h5: {
         fontWeight: 500,
         fontFamily: "lexend, sans-serif",
         letterSpacing: "-0.5px",
         fontSize: "1.125rem",
         color: colours.black,
         lineHeight: "calc(100% + 4px)",
         [theme.breakpoints.up("sm")]: {
            fontSize: "1.375rem",
         },
      },
      h6: {
         fontWeight: 500,
         fontFamily: "lexend, sans-serif",
         letterSpacing: "-0.5px",
         fontSize: "1rem",
         color: colours.black,
         lineHeight: "calc(100% + 4px)",
         [theme.breakpoints.up("sm")]: {
            fontSize: "1.125rem",
         },
      },
      subtitle1: {
         fontWeight: 400,
         fontFamily: "lexend, sans-serif",
         textTransform: "initial",
         lineHeight: "calc(100% + 4px)",
         color: colours.black,
         fontSize: "18px",
         [theme.breakpoints.down("md")]: {
            fontSize: "16px",
         },
      },
      subtitle2: {
         fontWeight: 400,
         fontFamily: "lexend, sans-serif",
         textTransform: "initial",
         lineHeight: "calc(100% + 4px)",
         color: colours.black,
         fontSize: "16px",
         [theme.breakpoints.down("md")]: {
            fontSize: "14px",
         },
      },
      body1: {
         fontWeight: 300,
         fontFamily: "lexend, sans-serif",
         lineHeight: "calc(100% + 4px)",
         color: colours.gray[500],
         "&.MuiTypography-gutterBottom": {
            marginBottom: "0.8rem",
         },
         [theme.breakpoints.down("md")]: {
            fontSize: "14px",
         },
      },
      body2: {
         fontWeight: 300,
         fontFamily: "lexend, sans-serif",
         lineHeight: "calc(100% + 4px)",
         color: colours.gray[500],
         "&.MuiTypography-gutterBottom": {
            marginBottom: "0.8rem",
         },
      },
      button: {
         fontSize: "16px",
         fontWeight: 500,
         letterSpacing: 0,
         fontFamily: "lexend, sans-serif",
         textTransform: "unset",
      },
      caption: {
         fontSize: "0.8125rem",
         fontFamily: "lexend, sans-serif",
      },
      overline: {
         color: colours.gray[500],
         fontFamily: "lexend, sans-serif",
         fontWeight: 500,
         lineHeight: 1.2,
         fontSize: "0.8125rem",
         letterSpacing: 2,
      },
   },
});

export default theme;
