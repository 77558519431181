import React, { useEffect, useState, memo } from "react";
import { useNavigate } from "react-router-dom";
import {
   Box,
   Typography,
   List,
   ListSubheader,
   ListItem,
   // ListItemIcon,
   IconButton,
   ListItemText,
   Chip,
   Button,
   Paper,
   Tooltip,
   Link,
   CircularProgress
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { colours, units } from "../constants/constants";
import ButtonTile from "../components/button-tile";
import CategorySelectionDialog from "../components/category-selection-dialog";
// import ProductSelectionDialog from "../components/product-selection-dialog";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
// import IconFolderStar from "../components/svg-icons/icon-folder-star";
import { getUserId } from "../helpers/local-storage";
import { saveWishedCategory } from "../helpers/firebase";
// import { saveWishedCategory, saveWishedProducts } from "../helpers/firebase";
// import IconShoppingBagSide from "../components/svg-icons/icon-shopping-bag-side";
import IconCheckCircle from "../components/svg-icons/icon-check-circle";
import { useSnackbar } from 'notistack';
import DeleteConfirmationDialog, { confirmDialog } from '../components/delete-confirmation-dialog';
import {Helmet} from "react-helmet";

const Wishes = (props) => {
   const classes = useStyles();
   let navigate = useNavigate();
   const { isValidated } = props;
   if (!isValidated || !getUserId())
      navigate('/', { replace: true});

   // State
   const [catDialogOpen, setCatDialogOpen] = useState(false);
   // const [prodDialogOpen, setProdDialogOpen] = useState(false);
   const [catDataBase, setCatDataBase] = useState([]);
   const [loading, setLoading] = useState(false);
   const [selectedCats, setSelectedCats] = useState([]);
   // const [selectedProds, setSelectedProds] = useState([]);
   // const [editingItem, setEditingItem] = useState(null);
   const { enqueueSnackbar } = useSnackbar();

   window.onbeforeunload = () => {
      return "Are you sure you want to leave?";
   };

   // Handlers
   const setCategoryHandler = (cats) => {
      setSelectedCats(cats);
      enqueueSnackbar(`Your categories were added to your Wishlist`, {
         variant: 'success',
         anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
        },
      });
   };

   const catDialogHandler = () => {
      setCatDialogOpen(!catDialogOpen);
   };

   const deleteCategoryHandler = (id) => {
      const newArray = selectedCats.filter(cats => cats.id !== id);
      setSelectedCats(newArray);
   }

   // const resetEditing = () => {
   //    setEditingItem(null);
   // };

   // const prodDialogHandler = () => {
   //    setProdDialogOpen(!prodDialogOpen);
   // };

   // const setProductHandler = (prod, index) => {
   //    if (index !== null) {
   //       const editedProducts = [...selectedProds];
   //       editedProducts[index] = prod;
   //       setSelectedProds(editedProducts);
   //       setEditingItem(null);
   //       enqueueSnackbar('Item edited successfully', {
   //          variant: 'success',
   //          anchorOrigin: {
   //             vertical: 'top',
   //             horizontal: 'right',
   //         },
   //       })
   //    } else {
   //       setSelectedProds([...selectedProds, prod]);
   //       setEditingItem(null);
   //       enqueueSnackbar('Great, item added to you Wishlist', {
   //          variant: 'success',
   //          anchorOrigin: {
   //             vertical: 'top',
   //             horizontal: 'right',
   //         },
   //       })
   //    }
   // };

   // const editProductHandler = (
   //    productName,
   //    productURL,
   //    productType,
   //    productComments,
   //    index
   // ) => {
   //    setEditingItem({
   //       productName: productName,
   //       productURL: productURL,
   //       productType: productType,
   //       productComments: productComments,
   //       index: index,
   //    });
   //    prodDialogHandler();
   // };

   // const deleteProductHandler = (index) => {
   //    const editedProducts = [...selectedProds];
   //    editedProducts.splice(index, 1);
   //    setSelectedProds(editedProducts);
   //    enqueueSnackbar('Item removed from your Wishlist', {
   //       variant: 'success',
   //       anchorOrigin: {
   //          vertical: 'top',
   //          horizontal: 'right',
   //      },
   //    })
   // };

   const submitWishlistHandler = async () => {
      setLoading(true);
      try{
         const getUser = getUserId();
         if (selectedCats.length > 0) {
            selectedCats.map((cat) => {
               const newCat = {
                  userId: getUser,
                  ...cat,
               };
               return saveWishedCategory(newCat);
            });
         }
         // if (selectedProds.length > 0) {
         //    selectedProds.map((prod) => {
         //       const newProd = {
         //          userId: getUser,
         //          ...prod,
         //       };
         //       return saveWishedProducts(newProd);
         //    });
         // }
         setLoading(false);
         window.dataLayer = window.dataLayer || [];
         window.dataLayer.push({
            event: "wishesSubmitted"
         })
         navigate('/thank-you', { replace: true, valid: true });
      } catch (error) {
         console.log(error);
         setLoading(false);
         enqueueSnackbar(`Oh no, something went wrong`, {
            variant: 'error',
            anchorOrigin: {
               vertical: 'top',
               horizontal: 'right',
            },
         });
      }
   };

   const getCatTrail = (id) => {
      let isRoot = false;
      let theParentArray = []
      const thisCat = catDataBase.filter(cat => cat.id === id);
      let theParent = thisCat[0].parentId;
      let theName = []
      while(!isRoot){
         // eslint-disable-next-line
         theParentArray = catDataBase.filter(cat => cat.id === theParent);
         if (theParentArray.length > 0){
            theParent = theParentArray[0].parentId;
            theName.unshift(theParentArray[0].categoryName);
         }else{
            isRoot = true;
         }
      }
      return theName.join(" > ");
   }

   useEffect(() => {
      let subscription = true;
      const retrieveCats = async () => {
         if (subscription) {
            await fetch("cats.json", {
               headers: {
                  "Content-Type": "application/json",
                  Accept: "application/json",
               },
            })
               .then((response) => {
                  return response.json();
               })
               .then((response) => {
                  setCatDataBase(response);
               })
               .catch((error) => {
                  console.error(error);
                  enqueueSnackbar(`Oh no! Something went wrong`, {
                     variant: 'error',
                     anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    },
                  });
               });
         }
      };
      retrieveCats();
      return () => {
         subscription = false;
      };
   }, [enqueueSnackbar]);

   const isContinueDisabled =
      (selectedCats.length === 0) || loading ? true : false;
      // (selectedCats.length === 0 && selectedProds.length === 0) || loading ? true : false;

   return (
      <>
         <Helmet>
            <meta charSet="utf-8" />
            <title>HurryApp - Your wishes</title>
            <meta name="description" content="What's your wish right now?" />
            <link rel="canonical" href="https://hurryapp.com" />
         </Helmet>
         <Box className={classes.mainWrapper}>
            <DeleteConfirmationDialog />
            <Box className={classes.sideGraph}>
               <Link href="https://hurryapp.com.au">
                  <img
                     width="80"
                     height="55"
                     src="/images/HurryApp-logo1-white.svg"
                     alt="HurryApp logo"
                     style={{ position: "relative", zIndex: 1 }}
                     className={classes.mainLogo}
                  />
                  <img
                     width="80"
                     height="55"
                     src="/images/HurryApp-isotipo-white.svg"
                     alt="HurryApp logo"
                     style={{ position: "relative", zIndex: 1 }}
                     className={classes.mobileMainLogo}
                  />
               </Link>
            </Box>
            <Box className={classes.sideWishes}>
               <Box className={classes.wishesContainer}>
                  <Box className={classes.preHeader} display="flex" justifyContent="center">
                     <IconCheckCircle stroke={colours.primary} />
                     <Typography
                        variant="h5"
                        style={{ color: colours.primary, marginLeft: units[1] }}
                     >
                        Thanks for joining HurryApp
                     </Typography>
                  </Box>
                  <Typography variant="h1" align="center">
                     What's your wish right now?
                  </Typography>
                  <Box className={classes.wishesButtons}>
                     <ButtonTile
                        // icon={<IconFolderStar />}
                        title={`${selectedCats.length > 0 ? "Edit" : "Add"} categories`}
                        subtitle="Select one or multiple categories you would love to hear about"
                        className={classes.wishButton}
                        showSide
                        onClick={catDialogHandler}
                     />
                     <CategorySelectionDialog
                        isOpen={catDialogOpen}
                        handleClose={catDialogHandler}
                        saveCategory={setCategoryHandler}
                        categories={catDataBase}
                        savedCategories={selectedCats}
                        listener={selectedCats}
                     />
                     {/* <ButtonTile
                        icon={<IconShoppingBagSide />}
                        title="Add a product or service"
                        subtitle="Describe the product or service you are interested in"
                        className={classes.wishButton}
                        onClick={prodDialogHandler}
                     />
                     <ProductSelectionDialog
                        isOpen={prodDialogOpen}
                        handleClose={prodDialogHandler}
                        saveProduct={setProductHandler}
                        editItem={editingItem}
                        resetEditing={resetEditing}
                     /> */}
                  </Box>
                  <Box className={classes.wishesList}>
                     <Paper sx={{ width: "100%", overflow: "hidden" }}>
                        <List
                           sx={{
                              width: "100%",
                              bgcolor:
                                 selectedCats.length > 0
                                 // selectedProds.length > 0
                                    ? colours.white
                                    : colours.gray[100],
                           }}
                           className={classes.wishesMainList}
                           component="nav"
                           aria-labelledby="nested-list-subheader"
                           subheader={
                              <ListSubheader
                                 component="div"
                                 id="nested-list-subheader"
                                 className={classes.wishesHeader}
                              >
                                 Your HurryApp Wishlist
                              </ListSubheader>
                           }
                        >
                           {/* {selectedCats.length > 0 || selectedProds.length > 0 ? ( */}
                           {selectedCats.length > 0 ?
                              selectedCats.map((cat, index) => {
                                 return (
                                    <ListItem
                                       key={`ha-category-${index}`}
                                       className={classes.wishItemList}
                                       secondaryAction={
                                          <>
                                             <IconButton
                                                edge="end"
                                                aria-label="edit"
                                                sx={{ marginRight: 0 }}
                                                onClick={catDialogHandler}
                                             >
                                                <Tooltip title="Edit">
                                                   <EditIcon />
                                                </Tooltip>
                                             </IconButton>

                                             <IconButton
                                                edge="end"
                                                color="warning"
                                                aria-label="delete"
                                                sx={{ marginRight: 0 }}
                                                onClick={() => {
                                                   confirmDialog( `Are you sure you want to delete the following category: "${cat.categoryName}"?`, () => {
                                                      deleteCategoryHandler(cat.id);
                                                      enqueueSnackbar(`Category removed from your Wishlist`, {
                                                         variant: 'success',
                                                         anchorOrigin: {
                                                            vertical: 'top',
                                                            horizontal: 'right',
                                                      },
                                                      })
                                                   } );
                                                }}
                                             >
                                                <Tooltip title="Delete">
                                                   <DeleteIcon />
                                                </Tooltip>
                                             </IconButton>
                                          </>
                                       }
                                    >
                                       <ListItemText
                                          primary={
                                             (
                                                <Box ml={1}>
                                                   <Chip
                                                      color="primary"
                                                      label="Category"
                                                      size="small"
                                                   />
                                                   <Typography component="div" variant="overline" sx={{fontWeight: '400', fontSize: '11px', lineHeight: '1.2', letterSpacing: '0', textTransform:'unset', marginTop: '8px', marginBottom: '4px'}}>{getCatTrail(cat.id)}</Typography>
                                                   <Typography component="div" variant="subtitle1">{cat.categoryName}</Typography>
                                                </Box>
                                             )}
                                       />
                                    </ListItem>
                                 )
                              }
                           ) : (
                              <ListItem>
                                 <ListItemText
                                    className={classes.emptyItem}
                                 >
                                    <img
                                       src="/images/ha-empty-state.svg"
                                       width="147"
                                       alt="Wishlist is empty"
                                       height="133"
                                    />
                                    <Typography
                                       variant="subtitle1"
                                       sx={{ color: colours.gray[300] }}
                                    >
                                       Your Wishlist is empty
                                    </Typography>
                                 </ListItemText>
                              </ListItem>
                           )}
                        </List>
                     </Paper>
                  </Box>
                  <Box className={classes.wishesActions}>
                     <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        fullWidth
                        disabled={isContinueDisabled}
                        onClick={submitWishlistHandler}
                     >
                        {loading ? <CircularProgress size={24} /> : "Submit your Wishlist"}
                     </Button>
                     {/* {selectedCats.length > 0 || selectedProds.length > 0 ? */}
                     {selectedCats.length > 0 ?
                        null
                     : <Button variant="text" color="secondary" sx={{ color: colours.gray[400], fontSize: 14, margin: "16px 0", display: 'block', cursor: 'pointer'}} onClick={() => navigate('/thank-you', { replace: true})}>No thanks, skip this step.</Button>}
                  </Box>
               </Box>
            </Box>
         </Box>
      </>
   );
};

const useStyles = makeStyles((theme) => ({
   mainWrapper: {
      width: "100%",
      minHeight: "100vh",
      display: "flex",
      flexDirection: "column",
      [theme.breakpoints.up('sm')]: {
         flexDirection: "row",
      },
   },
   sideGraph: {
      flex: '0 0 65px',
      background: `${colours.primary} url(/images/circled-pattern.png) repeat center center`,
      overflow: "hidden",
      borderBottomRightRadius: "100px",
      position: "relative",
      flexDirection: "column",
      justifyContent: "flex-start",
      alignItems: "center",
      boxSizing: "border-box",
      padding: "5px 16px",
      display: 'flex',
      [theme.breakpoints.up('sm')]: {
         padding: "16px",
      },
      [theme.breakpoints.up('md')]: {
         padding: "46px 40px 56px 40px",
         flex: "0 0 170px",
         minWidth: 170,
      },
   },
   mainLogo: {
      display: 'none',
      [theme.breakpoints.up("md")]: {
         display: 'block',
         width: 80,
      },
   },
   mobileMainLogo: {
      width: 35,
      [theme.breakpoints.up("md")]: {
         display: 'none',
      },
   },
   illustrationImage: {
      width: "100%",
      height: "auto",
      position: "relative",
   },
   sideWishes: {
      flex: 1,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      padding: '16px',
      marginBottom: '32px',
      [theme.breakpoints.up('md')]: {
         padding: '24px',
      },
      [theme.breakpoints.up('lg')]: {
         padding: '32px',
         marginRight: "100px",
      }
   },
   wishesContainer: {
      flex: 1,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      margin:'16px 0'
   },
   preHeader: {
      marginBottom: units[1],
      [theme.breakpoints.up('md')]: {
         marginBottom: units[2],
      },
   },
   wishesButtons: {
      maxWidth: 600,
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      marginTop: units[3],
      marginBottom: units[3],
      [theme.breakpoints.up('sm')]: {
         marginTop: units[4],
         marginBottom: units[4],
      },
      [theme.breakpoints.up('md')]: {
         marginTop: units[5],
         marginBottom: units[5],
      }
   },
   wishButton: {
      flex: 1,
      [theme.breakpoints.up('md')]: {
         flex: 1,
      }
   },
   wishesList: {
      maxWidth: 600,
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
   },
   wishesMainList: {
      minHeight: 'auto',
      [theme.breakpoints.up('md')]: {
         minHeight: 300,
      }
   },
   emptyItem: {
      textAlign: "center",
      [theme.breakpoints.up('md')]: {
         padding: "24px 8px",
      }
   },
   wishesActions: {
      maxWidth: 600,
      width: "100%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      marginTop: units[3],
      [theme.breakpoints.up("md")]: {
         marginTop: units[5],
      },
   },
   wishesHeader: {
      backgroundColor: colours.gray[500] + "!important",
      color: colours.white + "!important",
      fontSize: "18px !important",
   },
   wishItemList: {
      borderBottom: `solid 1px ${colours.gray[200]}`,
      '&:last-child': {
         borderBottom: `none`,
      },
      padding: "8px !important",
      paddingRight: '100px !important',
      [theme.breakpoints.up("md")]: {
         padding: "8px 16px !important",
      },
   },
   mobileImage: {
      textAlign: 'center',
      marginBottom: '32px',
      [theme.breakpoints.up('md')]: {
         display: 'none'
      },
   }
}));

export default memo(Wishes);
