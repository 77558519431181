import { initializeApp } from "firebase/app";
import { getFirestore, addDoc, collection, query, where, getDocs } from 'firebase/firestore/lite';
import { saveUserId, clearUserId } from '../helpers/local-storage';

const firebaseConfig = {
  apiKey: "AIzaSyCLz8oqjYGXt4YuR-zadqvLR5_kFKWyfzI",
  authDomain: "hurryapp-teaser-campaign.firebaseapp.com",
  projectId: "hurryapp-teaser-campaign",
  storageBucket: "hurryapp-teaser-campaign.appspot.com",
  messagingSenderId: "148050160379",
  appId: "1:148050160379:web:f2c87a58ea372313c39e5e",
  measurementId: "G-YBEF7TH0S3"
};

export const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);

// const categoryQuery = query(collection(db, 'categories'));

export const saveWishedCategory = async (data) => {
  return await addDoc(collection(db, "wishes-categories"), data);
}

export const saveWishedProducts = async (data) => {
  return await addDoc(collection(db, "wishes-products"), data);
}

export const saveForm = async (data) => {
  const haRegistrations = await addDoc(collection(db, 'registrations'), data);
  saveUserId(haRegistrations.id);
}

export const emailChecker = async (email) => {
  const emailResults = [];
  const emailCheckQuery = query(collection(db, 'registrations'), where("email", "==", email));
  const querySnapshot = await getDocs(emailCheckQuery);
  querySnapshot.forEach((doc) => {
    emailResults.push(doc.data());
  });
  if (emailResults.length > 0) {
    return true;
  }
  return false;
}

export const saveComments = async (data) => {
  await addDoc(collection(db, 'customer-feedback'), data);
  return clearUserId();
}