import React, { useState, useEffect, useRef } from "react";
import {
   Box,
   Typography,
   TextField,
   // InputLabel,
   // FormControl,
   // MenuItem,
   // Select,
   Button,
   Checkbox,
   Link,
   CircularProgress,
} from "@mui/material";
import { colours } from "../constants/constants";
import { makeStyles } from "@mui/styles";
import globalStyling from "../constants/global-styling";
import { isValidEmail } from "../helpers/field-validator";
import { saveForm, emailChecker } from "../helpers/firebase";
import { useNavigate } from "react-router-dom";
import TermsDialog from "../components/terms-dialog";
import { useSnackbar } from "notistack";
import ReCAPTCHA from "react-google-recaptcha";
import { getAuth, signInAnonymously } from "firebase/auth";

const Registration = (props) => {
   const { setIsValidated } = props;
   const classes = useStyles();
   const globalClasses = globalStyling();
   let navigate = useNavigate();
   const firsNameEl = useRef(null);
   const [firstName, setFirstName] = useState("");
   const [lastName, setLastName] = useState("");
   const [email, setEmail] = useState("");
   // const [age, setAge] = useState("");
   // const [gender, setGender] = useState("");
   const [terms, setTerms] = useState(false);
   const [dialogTermsOpen, setDialogTermsOpen] = useState(false);
   const [loading, setLoading] = useState(false);
   const { enqueueSnackbar } = useSnackbar();
   const auth = getAuth();

   // Recaptcha Function
   const [RecapValue, setRecapValue] = useState(false);
   function onRecaptchaChange(value) {
      if (value) {
         setRecapValue(true);
      } else {
         setRecapValue(false);
      }
   }

   // MailChimp Clound Function
   const mailChimp = async () => {
      const url =
         "https://us-central1-hurryapp-teaser-campaign.cloudfunctions.net/addSubscriber";
      const data = { email: email, firstname: firstName, lastname: lastName };
      const options = {
         method: "post",
         headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",
         },
         body: JSON.stringify(data),
      };

      await fetch(url, options)
         .then((data) => {
            console.log(data.json());
         })
         .catch((er) => {
            console.log(er);
         });
   };

   const isContinueDisabled =
      !RecapValue ||
      !firstName ||
      !lastName ||
      !isValidEmail(email) ||
      // !age ||
      // !gender ||
      !terms ||
      loading;

   const formHandler = () => {
      setLoading(true);
      // LocalStorage checker
      if (typeof Storage !== void 0) {
         signInAnonymously(auth)
            .then(async () => {
               const existInDb = await emailChecker(email);
               if (!existInDb) {
                  await saveForm({
                     fullName: `${firstName} ${lastName}`,
                     firstName: firstName,
                     lastName: lastName,
                     email: email,
                     // age: age,
                     // gender: gender,
                  });
                  setIsValidated(true);
                  mailChimp();
                  setLoading(false);
                  window.dataLayer = window.dataLayer || [];
                  window.dataLayer.push({
                     event: "registrationSubmitted"
                  })
                  navigate("/your-wishes", { replace: true, valid: true });
               }else{
                  setLoading(false);
                  enqueueSnackbar(
                     `You are already registered. We'll keep in touch`,
                     {
                        variant: "warning",
                        anchorOrigin: {
                           vertical: "top",
                           horizontal: "right",
                        },
                     }
                  );
               }
            })
            .catch((error) => {
               const errorCode = error.code;
               const errorMessage = error.message;
               console.log(errorCode + " - " + errorMessage);
               setLoading(false);
               enqueueSnackbar(`Oh no, something went wrong`, {
                  variant: "error",
                  anchorOrigin: {
                     vertical: "top",
                     horizontal: "right",
                  },
               });
            });
      } else {
         setLoading(false);
         enqueueSnackbar(`Oh no, something went wrong, try another browser`, {
            variant: "error",
            anchorOrigin: {
               vertical: "top",
               horizontal: "right",
            },
         });
      }
   };

   const firstNameHandler = (event) => {
      setFirstName(event.target.value);
   };

   const lastNameHandler = (event) => {
      setLastName(event.target.value);
   };

   const emailHandler = (event) => {
      setEmail(event.target.value);
   };

   // const ageHandler = (event) => {
   //    setAge(event.target.value);
   // };

   // const genderHandler = (event) => {
   //    setGender(event.target.value);
   // };

   const termsHandler = () => {
      setTerms(!terms);
   };

   const termsDialogHandler = (event) => {
      setDialogTermsOpen(event.target.value);
   };

   useEffect(() => {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const urlEmail = urlParams.get('email');

      if(urlEmail) {
         setEmail(urlEmail);
         firsNameEl.current.focus();
      }
   }, []);

   return (
      <Box className={classes.mainWrapper}>
         <Box className={classes.sideGraph}>
            <Link href="https://hurryapp.com.au">
               <img
                  width="80"
                  height="55"
                  src="/images/HurryApp-logo1-white.svg"
                  alt="HurryApp logo"
                  style={{ position: "relative", zIndex: 1 }}
                  className={classes.mainLogo}
               />
               <img
                  width="80"
                  height="55"
                  src="/images/HurryApp-isotipo-white.svg"
                  alt="HurryApp logo"
                  style={{ position: "relative", zIndex: 1 }}
                  className={classes.mobileMainLogo}
               />
            </Link>
            <img
               className={classes.illustrationImage}
               width="498"
               height="530"
               src="/images/hu-illustration-2.svg"
               alt="Enter the world of HurryApp"
            />
            <Box className={classes.clienteQuote}>
               <img
                  src="/images/user-thumbnail-2.png"
                  width="80"
                  height="80"
                  alt="user testimonial"
               />
               <div>
                  <Typography
                     variant="body2"
                     sx={{
                        color: colours.white,
                        marginBottom: "24px",
                        opacity: "0.8",
                     }}
                  >
                     “This is what I need in my life. I'm always on the hunt to
                     save money. I love that I have control over what offers I
                     see. I hate it when companies keep spamming me after I've
                     bought something or visited their website. HurryApp is
                     going to save me so much money and I can shop guilt free"
                  </Typography>
                  <Typography variant="body2" sx={{ color: colours.white }}>
                     Louise Caldicott
                     <br />
                     Mum of train obsessed toddler | Sydney
                  </Typography>
               </div>
            </Box>
         </Box>
         <Box className={classes.sideForm}>
            <Box className={classes.formContainer}>
               <Typography
                  className={classes.formHeader}
                  variant="h1"
                  align="center"
               >
                  The smarter way to shop online
               </Typography>
               <Typography
                  variant="body1"
                  align="center"
                  style={{ lineHeight: "24px", marginBottom: 32 }}
               >
                  Be the first to step into the future of online shopping and beat the high prices. Limited spots available.
               </Typography>
               <div>
                  <Box className={globalClasses.formGroup}>
                     <TextField
                        onChange={(event) => firstNameHandler(event)}
                        variant="outlined"
                        label="First Name"
                        type="text"
                        autoComplete="off"
                        fullWidth
                        value={firstName}
                        className={globalClasses.inputs}
                        inputRef={firsNameEl}
                     />
                     <TextField
                        onChange={(event) => lastNameHandler(event)}
                        variant="outlined"
                        label="Last Name"
                        type="text"
                        autoComplete="off"
                        value={lastName}
                        fullWidth
                        className={globalClasses.inputs}
                     />
                  </Box>
                  <TextField
                     onChange={(event) => emailHandler(event)}
                     variant="outlined"
                     label="Email address"
                     type="email"
                     autoComplete="off"
                     fullWidth
                     value={email}
                     className={globalClasses.inputs}
                  />
                  {/* <FormControl fullWidth className={globalClasses.inputs}>
                     <InputLabel id="ha-age-label">Your age</InputLabel>
                     <Select
                        labelId="ha-age-label"
                        id="ha-age-field"
                        value={age}
                        label="Your age"
                        onChange={(event) => ageHandler(event)}
                     >
                        <MenuItem value={"18-24"}>18-24</MenuItem>
                        <MenuItem value={"25-34"}>25-34</MenuItem>
                        <MenuItem value={"35-44"}>35-44</MenuItem>
                        <MenuItem value={"45-54"}>45-54</MenuItem>
                        <MenuItem value={"55-64"}>55-64</MenuItem>
                        <MenuItem value={"65+"}>65+</MenuItem>
                     </Select>
                  </FormControl>
                  <FormControl fullWidth>
                     <InputLabel id="ha-gender-label">Gender</InputLabel>
                     <Select
                        labelId="ha-gender-label"
                        id="ha-gender-field"
                        value={gender}
                        label="Gender"
                        onChange={(event) => genderHandler(event)}
                     >
                        <MenuItem value={"female"}>Female</MenuItem>
                        <MenuItem value="male">Male</MenuItem>
                        <MenuItem value="other">Other</MenuItem>
                        <MenuItem value="prefer-not-to-say">
                           Prefer not to say
                        </MenuItem>
                     </Select>
                  </FormControl> */}

                  <div className="recaptcha-div">
                     <ReCAPTCHA
                        sitekey="6LdtXmIgAAAAAPQRDrzAs2cRoqfueKe4dx0iQTsL"
                        onChange={onRecaptchaChange}
                     />
                  </div>

                  <Box mt={3} display="flex" alignItems="center">
                     <Checkbox
                        onClick={termsHandler}
                        checked={terms}
                        tabIndex={-1}
                     />
                     <Typography variant="body2" style={{ fontSize: 12 }}>
                        I read and agree with HurryApp{" "}
                        <Link
                           href="#"
                           id="ha-registration-tscs"
                           onClick={() => setDialogTermsOpen(true)}
                        >
                           Terms and Conditions
                        </Link>
                        .
                     </Typography>
                     <TermsDialog
                        isOpen={dialogTermsOpen}
                        handleClose={termsDialogHandler}
                     />
                  </Box>
                  <Box mt={3} style={{ textAlign: "right" }}>
                     <Button
                        variant="contained"
                        color="primary"
                        fullWidth
                        size="large"
                        disabled={isContinueDisabled}
                        onClick={formHandler}
                     >
                        {loading ? <CircularProgress size={24} /> : "Join the Waitlist"}
                     </Button>
                  </Box>
               </div>
            </Box>
         </Box>
      </Box>
   );
};

const useStyles = makeStyles((theme) => ({
   mainWrapper: {
      width: "100%",
      minHeight: "100vh",
      display: "flex",
      flexDirection: "column",
      [theme.breakpoints.up("sm")]: {
         flexDirection: "row",
      },
   },
   clienteQuote: {
      position: "relative",
      display: "none",
      [theme.breakpoints.up("md")]: {
         display: "flex",
      },
      margin: "0 8px",
      "& img": {
         marginRight: "24px",
         [theme.breakpoints.up("lg")]: {
            marginRight: "32px",
         },
      },
   },
   sideGraph: {
      background: `${colours.primary} url(/images/circled-pattern.png) repeat center center`,
      flex: "0 0 65px",
      overflow: "hidden",
      borderBottomRightRadius: "100px",
      position: "relative",
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      alignItems: "center",
      boxSizing: "border-box",
      padding: "5px 16px",
      [theme.breakpoints.up('sm')]: {
         padding: "16px",
      },
      [theme.breakpoints.up("md")]: {
         padding: "40px 32px 48px 32px",
         flex: "0 0 400px",
         minWidth: 400,
         justifyContent: "space-between",
      },
      [theme.breakpoints.up("lg")]: {
         padding: "48px 40px 56px 40px",
         flex: "0 0 520px",
         minWidth: 520,
      },
      "&:before": {
         position: "absolute",
         flex: 1,
         content: "' '",
         height: "70%",
         display: "none",
         backgroundColor: colours.primaryDark,
         width: "100%",
         bottom: "-20%",
         transform: "skewY(338deg)",
         zIndex: 0,
         [theme.breakpoints.up("md")]: {
            display: "block",
         },
      },
   },
   mainLogo: {
      display: 'none',
      [theme.breakpoints.up("md")]: {
         display: 'block',
         width: 80,
      },
   },
   mobileMainLogo: {
      width: 35,
      [theme.breakpoints.up("md")]: {
         display: 'none',
      },
   },
   illustrationImage: {
      width: "75%",
      height: "auto",
      position: "relative",
      margin: "24px 0",
      display: "none",
      [theme.breakpoints.up("md")]: {
         display: "block",
      },
   },
   sideForm: {
      flex: 1,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      padding: "16px",
      marginBottom: "32px",
      [theme.breakpoints.up("md")]: {
         padding: "24px",
      },
      [theme.breakpoints.up("lg")]: {
         padding: "32px",
      },
   },
   formContainer: {
      maxWidth: 580,
      width: "100%",
      flex: 1,
   },
   mobileImage: {
      textAlign: "center",
      marginBottom: "32px",
      [theme.breakpoints.up("md")]: {
         display: "none",
      },
   },
   formHeader: {
      color: colours.secondary,
      marginTop: "16px !important",
      marginBottom: "24px !important",
      [theme.breakpoints.up("md")]: {
         marginBottom: "32px !important",
      },
   },
}));

export default Registration;
