import React, { useState } from 'react';
import data from '../cats.json';
import { setDoc, doc } from 'firebase/firestore/lite';
import { db } from '../helpers/firebase';
import { Button } from '@mui/material';

const Import = () => {
   const [isLoading, setIsLoading] = useState(true);
   const saveData = async (data) => {
      return await setDoc(doc(db, 'categories', String(data.id)), data);
   }

   const runData = () => {
      data.map((data) => {
         saveData(data);
         return data;
      });
      setIsLoading(false);
   }

   return (
      <>
       <h1>{isLoading ? 'Working...' : 'All done!'}</h1>
       <Button variant="contained" onClick={runData}>Execute</Button>
      </>
   )
};

export default Import;