export const LOCAL_STORAGE_USER_ID = 'ha_local_storage_user_id';

export const colours = {
  primary: '#FF860D',
  primaryDark: '#F07800',
  primaryLight: '#FDB28E',
  secondary: '#333333',
  black: '#333333',
  white: '#FFFFFF',
  gray: {
    100: '#F7F5F4',
    200: '#F0F0EF',
    300: '#BFBFBF',
    400: '#959595',
    500: '#696969',
  },
  red: '#FE613B',
  greenLight: '#8AD038',
  green: '#6DC93D',
  greenDark: '#53a229',
};

export const units = {
  1: '8px',
  2: '16px',
  3: '24px',
  4: '32px',
  5: '40px',
  6: '48px',
  7: '56px',
}