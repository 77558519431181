const MuiBackdrop = {
   MuiBackdrop: {
     styleOverrides: {
       root: {
         backgroundColor: 'rgba(111, 126, 140, 0.2)',
         backdropFilter: "blur(8px)",
       },
       invisible: {
        backgroundColor: 'unset',
        backdropFilter: "unset",
       }
     },
   },
 };

 export default MuiBackdrop;