import React, { useState } from "react";
import { ThemeProvider } from "@mui/material/styles";
import Registration from "./layouts/registration";
import Wishes from "./layouts/wishes";
import ha_theme from "./constants/MUI-theme";
import Import from "./layouts/import";
import {
   BrowserRouter as Router,
   Routes,
   Route,
   Navigate,
} from "react-router-dom";
import { SnackbarProvider } from "notistack";
import ThankYou from "./layouts/thank-you";
import TagManager from "react-gtm-module";
import {Helmet} from "react-helmet";

function App() {
   const [isValidated, setIsValidated] = useState(true);

   const tagManagerArgs = {
      gtmId: "GTM-TWNV88J",
      events: {
         wishesSubmitted: 'Wishes submitted',
         registrationSubmitted: 'Registration submitted'
      }
   };

   TagManager.initialize(tagManagerArgs);

   return (
      <ThemeProvider theme={ha_theme}>
         <SnackbarProvider maxSnack={3}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>HurryApp - Express your interest</title>
                <meta name="description" content="Express your interest to be the first to get onboard in the future of online sales." />
                <link rel="canonical" href="https://hurryapp.com" />
            </Helmet>
            <Router>
               <Routes>
                  <Route path="*" element={<Navigate to="/" replace />} />
                  {isValidated && (
                     <Route
                        path="/your-wishes"
                        exact
                        element={<Wishes isValidated={isValidated} />}
                     />
                  )}
                  <Route path="/thank-you" exact element={<ThankYou />} />
                  <Route path="/import" exact element={<Import />} />
                  <Route
                     path="/"
                     exact
                     element={<Registration setIsValidated={setIsValidated} />}
                  />
               </Routes>
            </Router>
         </SnackbarProvider>
      </ThemeProvider>
   );
}

export default App;
