import React from "react";
import { colours } from "../../constants/constants";

const IconPlusCircle = (props) => {
   const { width = 24, height = 24, stroke = colours.black } = props;
   return (
      <svg
         style={{ width: width, height: height }}
         viewBox={`0 0 ${width} ${height}`}
         xmlns="http://www.w3.org/2000/svg" strokeWidth="1.5"
      >
         <path d="M12 7.5L12 16.5" fill="none" stroke={stroke} strokeLinecap="round" strokeLinejoin="round"></path>
         <path d="M7.5 12L16.5 12" fill="none" stroke={stroke} strokeLinecap="round" strokeLinejoin="round"></path>
         <path d="M0.750 12.000 A11.250 11.250 0 1 0 23.250 12.000 A11.250 11.250 0 1 0 0.750 12.000 Z" fill="none" stroke={stroke} strokeLinecap="round" strokeLinejoin="round"></path>
      </svg>
   );
};

export default IconPlusCircle;
